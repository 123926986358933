<template>
    <!-- Associate Image Component -->
    <AssociatesImageComponent v-model="associate.associateImage" />

    <!-- Notifications Component -->
    <Notifications ref="notification" />

    <form class="form" @submit.prevent="addAssociate">
        <section>
            <h3 class="form__section-title">Associate Info</h3>

            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label form--required">
                        Full Name
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Full Name" v-model="associate.name">

                    <div class="form__input-error" v-if="errors.fields.name">
                        {{ errors.fields.name[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Date of Birth
                    </label>

                    <input type="date" class="form__input" placeholder="Associate Full Name" v-model="associate.dob"
                        max="2008-12-31">

                    <div class="form__input-error" v-if="errors.fields.dob">
                        {{ errors.fields.dob[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Phone
                    </label>

                    <input type="number" class="form__input" placeholder="Associate Phone" v-model="associate.phone">

                    <div class="form__input-error" v-if="errors.fields.phone">
                        {{ errors.fields.phone[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Address
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Address" v-model="associate.address" disabled>

                    <div class="form__input-error" v-if="errors.fields.address">
                        {{ errors.fields.address[0] }}
                    </div>
                </div>
            </div>
        </section>

        <section>
            <h3 class="form__section-title">Login Information</h3>

            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label">
                        Email
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Email" v-model="associate.email">

                    <div class="form__input-error" v-if="errors.fields.email">
                        {{ errors.fields.email[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Username
                    </label>

                    <input type="text" class="form__input" placeholder="Associate Username"
                        v-model="associate.userName">

                    <div class="form__input-error" v-if="errors.fields.userName">
                        {{ errors.fields.userName[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Password
                    </label>

                    <input type="password" class="form__input" placeholder="******" v-model="associate.password">

                    <div class="form__input-error" v-if="errors.fields.password">
                        {{ errors.fields.password[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Confirm Password
                    </label>

                    <input type="password" class="form__input" placeholder="******"
                        v-model="associate.password_confirmation">

                    <div class="form__input-error" v-if="errors.fields.password_confirmation">
                        {{ errors.fields.password_confirmation[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        PIN
                    </label>

                    <input type="password" class="form__input" placeholder="****" v-model="associate.pin">

                    <div class="form__input-error" v-if="errors.fields.pin">
                        {{ errors.fields.pin[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Confirm PIN
                    </label>

                    <input type="password" class="form__input" placeholder="****" v-model="associate.pin_confirmation">

                    <div class="form__input-error" v-if="errors.fields.pin_confirmation">
                        {{ errors.fields.pin_confirmation[0] }}
                    </div>
                </div>
            </div>
        </section>

        <div class="form__buttons">
            <button class="button button--success">
                <img src="@/assets/icons/vuesax/linear/add-circle.svg" alt="">
                Add Associate
            </button>
        </div>
    </form>
</template>

<script setup>
import AssociatesImageComponent from './AssociatesImageComponent.vue'
import { associateModel } from '../Models/AssociateModel.js'
import Notifications from '@/common/Notifications.vue'
import { associatesServices } from '../Services/AssociatesServices.js'
import { ref, reactive, onMounted } from 'vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import { useClientStore } from '../../../stores/ClientStore'

const { addAssociateService, errors } = associatesServices()
const { sweetSuccess } = sweetAlerts()
const associate = reactive({ ...associateModel() })
const notification = ref(null);
const router = useRouter()
const { client } = useClientStore()

onMounted(() => { associate.address = client.hotelAddress })

async function addAssociate() {
    const response = await addAssociateService(associate)

    if (response.hasErrors) {
        notification.value.sendNotification('error', 'Error', response.message)
        return console.error(`Error added associated: ${response}`)
    }

    router.push('/')

    return sweetSuccess(response.data.message)
}
</script>