<template>
    <div class="associates-image">
        <!-- Notifications Component -->
        <Notifications ref="notification" />

        <img :src="imagePreview" class="associates-image__image" alt="" width="100" height="100" v-if="imagePreview">
        <img src="@/assets/images/user-profile.svg" class="associates-image__image" width="100" height="100" v-else>

        <label for="label-file" class="associates-image__input-label">
            <img src="@/assets/icons/vuesax/linear/edit-green.svg" alt="" width="15" height="15">
            Update Image
            <input type="file" class="associates-image__input-file" id="label-file" @change="getImage">
        </label>
    </div>
</template>

<script setup>
import Notifications from '@/common/Notifications.vue'
import { associatesServices } from '../Services/AssociatesServices'
import { ref, watch } from 'vue'

const props = defineProps(['associateId', 'imagePath'])

const { updateAssociateImageService } = associatesServices()
const emit = defineEmits(['update:modelValue'])
const notification = ref(null)
const filesPath = process.env.VUE_APP_FILES_PATH
let imageFile = null
let imagePreview = ref(null)

watch(() => props.imagePath, (imagePath) => { 
    if (imagePath) imagePreview.value = filesPath + imagePath 
})

function getImage(file) {
    imageFile = file.target.files[0]
    imagePreview.value = URL.createObjectURL(imageFile);

    if (props.associateId) return updateAssociteImage(imageFile)
    else return emit('update:modelValue', imageFile)
}

async function updateAssociteImage() {
    const file = { image: imageFile }
    const response = await updateAssociateImageService(props.associateId, file)

    if (response.hasErrors) {
        notification.value.sendNotification('error', 'Error', response.message)
        return console.error(`Error updating associate image: ${response}`)
    }

    return notification.value.sendNotification('success', 'Success', response.data.message)
}
</script>