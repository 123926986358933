import { reactive } from "vue"

export const associateModel = (fields) => {
    return reactive({
        name: fields?.name,
        email: fields?.email,
        userName: fields?.userName,
        password: null,
        password_confirmation: null,
        pin: null,
        pin_confirmation: null,
        dob: fields?.dob,
        phone: fields?.phone,
        address: fields?.address,
        associateImage: fields?.picture
    })
}